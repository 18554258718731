import { AxiosResponse, AxiosError } from 'axios';

/**
 * Mock AxiosResponse generator for valid responses
 * @returns A Promise resolving to a mocked AxiosResponse object
 */
const mockAxiosResponse = async (): Promise<AxiosResponse<any, any>> => {
    return {
        data: {},
        status: 200,
        statusText: "OK",
        headers: {},
        config: {},
    } as AxiosResponse<any, any>;
};

/**
 * Mock AxiosResponse generator for triggering token-related errors
 * @returns A mocked error object with the appropriate structure
 */
const mockTokenError = (): any => {
    return {
        response: {
            data: {
                detail: 'Given token not valid for any token type',
            },
            status: 401,
            statusText: 'Unauthorized',
            headers: {},
            config: {},
        },
    };
};

export { mockAxiosResponse, mockTokenError };
