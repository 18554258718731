import axios, { AxiosResponse } from "axios";
import { SessionUtils } from "./SessionUtils";
import { BrowserUtils } from "./BrowserUtils";
import { URLS } from "../urls";
export class RESTUtils {
    private static _API_URL: string = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL as string : '';
    private static _config = {
        headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": ""
        }
    };
    private static _configIMAGE = {
        headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
            "Authorization": ""
        }
    };
    private static _addBearerTokenFromStorage(): void {
        const user = SessionUtils.getCurrentUserSession();
        if (user && user.access_token) {
            this._config.headers["Authorization"] = "Bearer " + user.access_token;
        }
    }
    private static _addBearerTokenFromStorageIMAGE(): void {
        const user = SessionUtils.getCurrentUserSession();
        if (user && user.access_token) {
            this._configIMAGE.headers["Authorization"] = "Bearer " + user.access_token;
        }
    }
    public static getAPIUrl(): string {
        return this._API_URL;
    }

    public static async GET(url: string, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._config, config);
        this._addBearerTokenFromStorage();
        try {
            return await axios.get(url, Config);
        } catch (error: any) {
            return await this._handleTokenRefreshError(error, () =>
                axios.get(url, Config)
            );
        }
    }


    public static async POST(url: string, body: any = null, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._config, config);
        this._addBearerTokenFromStorage();
        try {
            return await axios.post(url, body, Config);
        } catch (error: any) {
            return await this._handleTokenRefreshError(error, () =>
                axios.post(url, body, Config)
            );
        }
    }
    public static async POSTIMAGE(url: string, body: any = null, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._configIMAGE, config);
        this._addBearerTokenFromStorageIMAGE();
        try {
            return await axios.post(url, body, Config);
        } catch (error: any) {
            return await this._handleTokenRefreshError(error, () =>
                axios.post(url, body, Config)
            );
        }
    }
    public static async PUT(url: string, body: any = null, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._config, config);
        this._addBearerTokenFromStorage();
        try {
            return await axios.put(url, body, Config);
        } catch (error: any) {
            return await this._handleTokenRefreshError(error, () =>
                axios.put(url, body, Config)
            );
        }
    }
    public static async DELETE(url: string, config: any = null): Promise<AxiosResponse<any, any>> {
        const Config = Object.assign(this._config, config);
        this._addBearerTokenFromStorage();
        try {
            return await axios.delete(url, Config);
        } catch (error: any) {
            return await this._handleTokenRefreshError(error, () =>
                axios.delete(url, Config)
            );
        }
    }
    private static isRefreshing = false;
    private static refreshPromise: Promise<any> | null = null;
    public static async _handleTokenRefreshError(
        error: any,
        retryCallback: () => Promise<AxiosResponse<any, any>>
    ): Promise<AxiosResponse<any, any>> {
        if (error?.response?.data?.detail === 'Given token not valid for any token type') {
            // Check if a refresh is already in progress
            if (!this.isRefreshing) {
                this.isRefreshing = true;
                this.refreshPromise = (async () => {
                    try {
                        const refreshResponse = await axios.post(this.getAPIUrl() + 'api/token/refresh/', {
                            refresh: SessionUtils.getRefreshToken(),
                        });

                        const newAccessToken = refreshResponse.data.access;
                        const newRefreshToken = refreshResponse.data.refresh;

                        SessionUtils.setTokens(newAccessToken, newRefreshToken); // Save the new tokens
                        this._addBearerTokenFromStorage(); // Add the new access token to headers

                        return refreshResponse;
                    } catch (refreshError) {
                        SessionUtils.removeCurrentUserSession();
                        BrowserUtils.redirect(URLS.AUTH.LOGIN_REDIRECT);
                        throw refreshError;
                    } finally {
                        this.isRefreshing = false;
                        this.refreshPromise = null;
                    }
                })();
            }

            // Wait for the ongoing refresh to complete
            await this.refreshPromise;

            // Retry the original request after refresh
            return retryCallback();
        }

        throw error; // Propagate the original error if it's not related to token
    }
}

