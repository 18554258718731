import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import Futura_PT_Book from "../fonts/Futura_PT_Book.ttf";

const lightTheme = responsiveFontSizes(
	createTheme({
		palette: {
			primary: {
				main: "#C60C30",
				contrastText: "#000000",
				footerIcon: "#ffffff",
			},
			secondary: {
				main: "#000",
				contrastText: "#000",
				cardHover: 'lightgrey',
				rowHover: '#eeeeee'
			},
			text: {
				primary: "#000",
				secondary: "#000",
				tabHover: "#000",
			},
			error: {
				main: "#d32f2f",
			},
			background: {
				default: "#e0e0e0",
				paper: "#FEFEFA",
			},
			header_footer_background: {
				main: "#000000",
			},
			header_footer_font_color: {
				main: "#ffffff",
				dark: "#000000",
			},
			header_footer_hover_button_color: {
				main: "#C60C30",
			},
			company_logo_url: "/images/logo-plb.png",
		},
		typography: {
			fontFamily: "Futura",
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					'@font-face': {
						fontFamily: 'Futura',
						src: `local('Futura'), local('Futura PT Book'), url(${Futura_PT_Book}) format('truetype')`,
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: "#282728",
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						border: "1px solid #bababa",
					},
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						color: '#000',
						"&.Mui-focused": {
							color: '#000',
						},
						"&.Mui-disabled": {
							color: "#555555",
						},
					},
				},
			},
			MuiCard: {
				styleOverrides: {
					root: {
						backgroundColor: '#eee',
					},
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						color: "#000000",
						"&.Mui-disabled": {
							color: "#888",
						},
					},
				},
			},
			MuiTextField: {
				styleOverrides: {
					root: {
						input: {
							// See https://stackoverflow.com/questions/74683182/mui-textfield-component-changes-background-color-to-white-when-you-choose-one-of
							"&:-webkit-autofill": {
								WebkitBoxShadow: "0 0 0 100px #fefefa inset",
								WebkitTextFillColor: "black",
							},
						},
						"& .MuiInputBase-input.Mui-disabled": {
							WebkitTextFillColor: "#333",
						},
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "#b2b2b2"
							},
							"&:hover fieldset": {
								borderColor: '#585a5c',
							},
							"&.Mui-focused fieldset": {
								borderColor: '#000000',
							},
							"&.Mui-disabled fieldset": {
								borderColor: "#d2d2d2",
							},
						},
						"& .MuiInputLabel-root": {
							color: '#000000',
						},
						"& .MuiInputLabel-asterisk": {
							transition: "color 0.2s ease",
						},
						"&:focus-within .MuiInputLabel-asterisk": {
							color: '#000000',
						},
						"& .MuiInputLabel-root.Mui-focused": {
							color: '#000000',
						},
						"& .MuiInputLabel-root.Mui-disabled": {
							color: '#555555',
						},
					},
				},
				defaultProps: {
					InputLabelProps: {
						shrink: true,
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: "none",
						fontSize: "1rem",
					},
					text: {
						color: '#000',
						backgroundColor: 'transparent',
						border: 'none',
						borderRadius: '50px',
						'&:hover': {
							backgroundColor: '#ccc',
						},
					},
				},
			},
			MuiRadio: {
				styleOverrides: {
					root: {
						color: '#666',
						'&.Mui-checked': {
							color: '#c60c30',
						},
						'&.Mui-disabled': {
							color: '#aaa',
						},
					},
				},
			},
			MuiPagination: {
				styleOverrides: {
					root: {
						'& .MuiPaginationItem-root': {
							border: 'none',
						},
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						textTransform: "none",
						fontSize: "1rem",
					},
				},
			},
			MuiListItemText: {
				styleOverrides: {
					root: {
						textTransform: "none",
						fontSize: "1rem",
					},
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						color: 'primary.contrastText',
						'&.Mui-checked': {
							color: 'primary.contrastText',
						},
						'&.Mui-disabled': {
							color: '#555555',
						},
						'&.Mui-disabled + .MuiFormControlLabel-label': {
							color: '#555555',
						},
					},
				},
			},
		}
	})
);

export default lightTheme;
