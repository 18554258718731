import React from 'react'
import { AccessRequiredProps } from './types'
import { SessionUtils } from '../../utilities/SessionUtils'
import { BrowserUtils } from '../../utilities/BrowserUtils';
import { URLS } from '../../urls';
import { useParams } from 'react-router-dom';
import { RESTUtils } from '../../utilities/RESTUtils';
import { mockAxiosResponse, mockTokenError } from './data';

function AccessRequired({ admin = false, children }: AccessRequiredProps) {
  let node: React.ReactNode = null;
  const user = SessionUtils.getCurrentUserSession();

  if (!user?.access_token) {
    BrowserUtils.redirect(URLS.AUTH.LOGIN);
    return null
  } else {
    const decodedJWT = parseJWT(user.access_token);
    if (decodedJWT.exp * 1000 < Date.now()) {
      RESTUtils._handleTokenRefreshError(mockTokenError(), () => mockAxiosResponse());
    } else if (!decodedJWT.isAdmin && !admin) {
      BrowserUtils.redirect(URLS.USER.DASHBOARD);
      return null
    }
    node = children;

  }

  return (
    <React.Fragment>
      {node}
    </React.Fragment>
  )
}
function LogInRequired({ admin = false, children }: AccessRequiredProps) {
  let node: React.ReactNode = null;
  const user = SessionUtils.getCurrentUserSession();
  console.log('login required', user)
  if (!user?.access_token) {
    BrowserUtils.redirect(URLS.AUTH.LOGIN);
    return null
  } else {
    const decodedJWT = parseJWT(user.access_token);
    if (decodedJWT.exp * 1000 < Date.now()) {
      RESTUtils._handleTokenRefreshError(mockTokenError(), () => mockAxiosResponse());
    }
    node = children;

  }

  return (
    <React.Fragment>
      {node}
    </React.Fragment>
  )
}
const parseJWT = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export default function checkIsAdmin(token: string) {
  const decodedJWT = parseJWT(token);
  return decodedJWT.isAdmin;
}

function IntakeRequired({ admin = false, children }: AccessRequiredProps) {
  const user = SessionUtils.getCurrentUserSession();
  let node: React.ReactNode = null;
  if (!user) {
    BrowserUtils.redirect(URLS.AUTH.LOGIN);
  }
  else if (!user.intake_complete && !user.is_admin) {
    BrowserUtils.redirect(URLS.AUTH.INTAKE);
  }
  else {
    node = children;
  }

  return (
    <React.Fragment>
      {node}
    </React.Fragment>
  )
}

function EditPageCheck({ admin = false, children }: AccessRequiredProps) {
  const { uuid } = useParams();

  const user = SessionUtils.getCurrentUserSession();
  let node: React.ReactNode = null;
  if (!user) {
    BrowserUtils.redirect(URLS.AUTH.LOGIN);
  }
  else if (user.uuid !== uuid && !user.is_admin) {
    BrowserUtils.redirect(URLS.AUTH.UNAUTHORIZED); // zyzz change this to be client dashboard at some point
  }
  else if (!user.intake_complete && !user.is_admin) {
    BrowserUtils.redirect(URLS.AUTH.INTAKE);
  }
  else {
    node = children;
  }
  return (<React.Fragment>{node}</React.Fragment>)
}
function NotPseudo({ admin = false, children }: AccessRequiredProps) {
  const user = SessionUtils.getCurrentUserSession();
  let node: React.ReactNode = null;
  if (user && !user.isPseudo) {
    BrowserUtils.redirect(URLS.USER.DASHBOARD);
  }
  else node = children;
  return (<React.Fragment>{node}</React.Fragment>)
}

function AlreadyLoggedIn({ admin = false, children }: AccessRequiredProps) {
  const user = SessionUtils.getCurrentUserSession();
  let node: React.ReactNode = null;
  if (user) BrowserUtils.redirect(URLS.DASHBOARD.ADMIN);
  else node = children;
  return (<React.Fragment>{node}</React.Fragment>)
}
export { AccessRequired, IntakeRequired, AlreadyLoggedIn, EditPageCheck, NotPseudo, LogInRequired }